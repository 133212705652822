<style scoped>
@import "~vue-toast-notification/dist/theme-sugar.css";
</style>
<style scoped>
.popover {
  max-width: 500px !important;
}
</style>
<template>
  <div class="event-wrapper p-2" style="cursor: pointer">
    <div
      v-bind:id="id"
    >
      <span v-html="title"></span>
    </div>
    <b-popover v-bind:target="id" triggers="hover">
      <template #title><span v-html="title" :style="'color:'+color"></span></template>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-3">
              <span style="text-decoration: underline">Type</span>
            </div>
            <div class="col-7">
              <span style="font-weight: bold">{{ extra.type }}</span>
            </div>
            <div class="col-3">
              <span
                v-if="
                  extra.type == 'FACTURE' || extra.type == 'BON DE LIVRAISON'
                "
                style="text-decoration: underline"
                >Numero</span
              >
              <span
                v-if="
                  !(extra.type == 'FACTURE' || extra.type == 'BON DE LIVRAISON')
                "
                style="text-decoration: underline"
                >Référence</span
              >
            </div>
            <div class="col-7">
              <span style="font-weight: bold">{{ extra.ref }}</span>
            </div>
            <div class="col-3">
              <span style="text-decoration: underline">Date échéance</span>
            </div>
            <div class="col-7">
              <span style="font-weight: bold">{{ dateFormated }}</span>
            </div>
          </div>
          <hr
            style="
              height: 1px;
              border-width: 0;
              color: gray;
              background-color: lightgray;
            "
          />
          <div class="row">
            <div class="col-3">
              <span
                v-if="extra.type_cible == 'CLIENT'"
                style="text-decoration: underline"
                >Client</span
              >
              <span
                v-if="extra.type_cible == 'FOURNISSEUR'"
                style="text-decoration: underline"
                >Fournisseur</span
              >
            </div>
            <div class="col-7">
              <span style="font-weight: bold">{{ extra.cible }}</span>
            </div>
            <div class="col-3">
              <span style="text-decoration: underline">Téléphone</span>
            </div>
            <div class="col-7">
              <span style="font-weight: bold">{{ extra.telephone }}</span>
            </div>
            <div class="col-3">
              <span style="text-decoration: underline">E-mail</span>
            </div>
            <div class="col-7">
              <span style="font-weight: bold">{{ extra.mail }}</span>
            </div>
            <div class="col-12 text-right" v-if="!(extra.type == 'FACTURE' || extra.type == 'NOTE D\'ACHAT' || extra.type == 'BON DE LIVRAISON')">
              <button type="button" class="btn btn-sm btn-success" @click="onValider"><i class="fa fa-check-double"></i> Valider règlement</button>
            </div>
          </div>
        </div>
        <div class="col-md-6"></div>
      </div>
    </b-popover>
    
  </div>
</template>
<script>
import moment from "moment"
import axios from "axios"
import Vue from "vue"


export default {

  name: "Event",
  data() {
    return {
      display: false,
      base_url: Vue.BASE_URL,
      
    };
  },
  props: {
    parent: { 
      type: Object,
      default: () => {},
      required: false
    },
    extra: {
      type: Object,
      default: () => {},
      required: false,
    },
    title: {
      type: [String],
      default: "",
      required: false,
    },
    date: {
      type: [String],
      default: "",
      required: false,
    },
    id: {
      type: [String],
      default: "event",
      required: false,
    },
    color: {
      type: [String],
      default: "event",
      required: false,
    },
  },
  computed: {
    dateFormated: function () {
      return moment(this.date).format("DD-MM-YYYY");
    },
  },
  methods: {
    onValider: function () {
      var that = this;
      
      axios
          .get(this.BASE_URL + "/echeance/encaisser", {
            params: {
              id_reglement: this.id,
              type_reglement: this.extra.type,
            },
          })
          .then(function (response) { 
            console.log(response);
            if (response.data.error === "") {
              console.log(response.data.message);
              that.$parent.fetchData();
              Vue.$toast.open({
                message: response.data.message,
                type: "success",
                position: "top-right",
                // all of other options may go here
              });
            } else {
              console.log("Erreur");
              Vue.$toast.open({
                message: response.data.error,
                type: "error",
                position: "top-right",
                // all of other options may go here
              });
            }
      });
      
    },
  },
  mounted: function (){
    // console.log(this)
  },
  created() {
    // this.display = false;
  },
};
</script>
