import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination"
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue'
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
// import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import Fullcalendar from '@fullcalendar/vue'
import DayGridPlugin from '@fullcalendar/daygrid'
import InteractionPlugin from '@fullcalendar/interaction'
import esLocale from '@fullcalendar/core/locales/es'
import frLocale from '@fullcalendar/core/locales/fr'
// import { BPopover } from 'bootstrap-vue'
import moment from 'moment'
import Event from './Event.vue'


Vue.component('downloadExcel', JsonExcel);



export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin,
        Fullcalendar
    },
    data: function() {
        return {
            pageTitle: this.$t('echeance.page_titre'),

            calendarOptions: {
                plugins: [DayGridPlugin, InteractionPlugin],
                initialView: 'dayGridMonth',
                locales: [esLocale, frLocale],
                locale: this.$i18n.locale,
                customButtons: {
                    myCustomButton: {
                        text: 'ACTUALISER',
                        click: this.fetchData
                    }
                },
                headerToolbar: {
                    right: '',
                    center: '',
                    left: ''
                },
                // eventSources: [
                //         this.BASE_URL + "/echeance/fetchdata",
                //     ]
                events: [],
                eventClassNames: function(info) {
                    var default_class = " mb-1"
                        // console.log(info.event.id);
                    return info.event.id + default_class;
                },
                eventContent: this.eventContent,

            },
            point_de_ventes: [],
            point_de_ventes_id: "",
            type_filtre: "",
            filtrer_par: "",
            search: "",
            calendarTitle: "",
            dateDebut: "",
            dateFin: "",
            datepicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },
            searchResults: []

        }
    },
    methods: {
        fetchData: function() {
            var api = this.$refs.mycalendar.getApi();
            if (api) {
                var viewapi = api.getCurrentData().viewApi;

                var dateStart = moment(viewapi.activeStart).format('DD/MM/YYYY');
                var dateEnd = moment(viewapi.activeEnd).format('DD/MM/YYYY');

                var params = {
                    "startDate": dateStart,
                    "endDate": dateEnd,
                    "point_de_ventes_id": this.point_de_ventes_id,
                    "type_filtre": this.type_filtre,
                    "filtrer_par": this.filtrer_par,
                    "search": this.search
                };
                var that = this;
                axios.get(this.BASE_URL + "/echeance/fetchdata", {
                    "params": params
                }).then(function(response) {
                    // console.log(response.data);
                    that.calendarOptions.events = response.data;
                });
            }
        },
        loadPointDeVente() {
            axios.get(this.BASE_URL + "/pointvente/getall/").then(response => {
                this.point_de_ventes = response.data;
            });
        },
        onNext: function() {
            var api = this.$refs.mycalendar.getApi();
            api.next();
            this.calendarTitle = api.view.title;
            this.fetchData();
        },
        onPrev: function() {
            var api = this.$refs.mycalendar.getApi();
            api.prev();
            this.calendarTitle = api.view.title;
            this.fetchData();
        },
        onNextYear: function() {
            var api = this.$refs.mycalendar.getApi();
            api.nextYear();
            this.calendarTitle = api.view.title;
            this.fetchData();
        },
        onPrevYear: function() {
            var api = this.$refs.mycalendar.getApi();
            api.prevYear();
            this.calendarTitle = api.view.title;
            this.fetchData();
        },
        onToday: function() {
            var api = this.$refs.mycalendar.getApi();
            api.today();
            this.calendarTitle = api.view.title;
            this.fetchData();
        },
        gotoDate: function(date) {
            var api = this.$refs.mycalendar.getApi();
            api.gotoDate(date);
            this.calendarTitle = api.view.title;
            this.fetchData();
            this.closeSearchModal();
        },
        resetFilter: function() {
            this.filtrer_par = "";
            this.search = "";
            this.point_de_ventes_id = "";
            this.type_filtre = "";
            this.searchResults = [];
            this.onToday();

            var api = this.$refs.mycalendar.getApi();
            var viewapi = api.getCurrentData().viewApi;
            this.dateDebut = moment(viewapi.activeStart).format('DD/MM/YYYY');
            this.dateFin = moment(viewapi.activeEnd).format('DD/MM/YYYY');

        },
        openSearchModal() {
            this.$bvModal.show("myModal");
        },
        closeSearchModal() {
            this.$bvModal.hide("myModal");
        },
        doSearch: function() {
            var api = this.$refs.mycalendar.getApi();
            if (api) {

                var params = {
                    "startDate": this.dateDebut,
                    "endDate": this.dateFin,
                    "point_de_ventes_id": this.point_de_ventes_id,
                    "type_filtre": this.type_filtre,
                    "filtrer_par": this.filtrer_par,
                    "search": this.search
                };
                var that = this;
                axios.get(this.BASE_URL + "/echeance/fetchdata", {
                    "params": params
                }).then(function(response) {
                    // console.log(response.data);
                    that.searchResults = response.data;
                });
            }
        },
        eventContent: function(arg) {
            // console.log(this);
            let El = document.createElement('div');
            var EventClass = Vue.extend(Event);
            var i = new EventClass({
                propsData: {
                    id: arg.event.id,
                    title: arg.event.title,
                    date: arg.event.startStr,
                    extra: arg.event.extendedProps,
                    color: arg.event.backgroundColor,
                },
                parent: this
            });
            i.$mount();
            El.appendChild(i.$el);
            let arrayOfDomNodes = [El]
            return { domNodes: arrayOfDomNodes }
        }
    },

    filters: {
        format: function(date) {
            console.log(date);
            return moment(date).format('DD/MM/YYYY')
        },
    },
    computed: {
        /*httpOptions(){
            return {headers: {'Authorization': "my-token"}} //table props -> :http-options="httpOptions"
        },*/
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        this.loadPointDeVente();

        this.motCle = "";
        this.criteriacol = "";

    },
    mounted: function() {
        this.fetchData();
        var api = this.$refs.mycalendar.getApi();
        this.calendarTitle = api.view.title;
        var viewapi = api.getCurrentData().viewApi;
        this.dateDebut = moment(viewapi.activeStart).format('DD/MM/YYYY');
        this.dateFin = moment(viewapi.activeEnd).format('DD/MM/YYYY');

    }
}